@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  text-rendering: optimizespeed;
}
/* Oswald */
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Oswald-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Oswald;
  src: url("./assets/fonts/Oswald-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Oswald;
  src: url("./assets/fonts/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Oswald;
  src: url("./assets/fonts/Oswald-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Oswald;
  src: url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizespeed;
}
@font-face {
  font-family: Oswald;
  src: url("./assets/fonts/Oswald-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizespeed;
}

body {
  margin: 0;
  font-family: "General Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
  color: #d04c8d !important;
}
span.breadcrumb {
  cursor: pointer;
}
span.breadcrumb:hover {
  text-decoration: none !important;
  color: #d04c8d !important;
}

/* ----------------------------------------- loader component starts -------------- */

.spinner {
  display: flex;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
}

.spinner div {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin: 0px 1px;
  background-color: #d44b9c;
  animation: sk-bouncedelay 1.4s infinite;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

/* ----------------------------------------- loader component ends -------------- */

/* ----------------------------------------- modal starts ------------------------ */

.react-responsive-modal-modal:not(.university) {
  background: #ffffff;
  border-radius: 80px;
  padding: 30px !important;
  overflow-y: visible !important;
  min-width: 488px;
}

.react-responsive-modal-modal {
  /* background: #ffffff;
  display: block !important;
  margin: auto !important;
  margin-top: 100px !important;
  border-radius: 30px !important;
  padding: 0px !important; */
  /* width: 50%; */
  /* min-width: 900px; */
  max-width: unset !important;
}

.modal-content {
  background: #ffffff !important;
  border-radius: 34px !important;
}

.react-responsive-modal-container {
  overflow-y: auto !important;
  height: 100vh !important;
  box-sizing: border-box;
}

.react-responsive-modal-closeButton:not(.university) {
  top: -16px !important;
  right: -10px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  width: 66px;
  height: 66px;
  align-items: center;
  justify-content: center;
}

button {
  outline: none;
}

/* ------------------------------------------ modal ends  -------------------------------------- */
.max-wd {
  max-width: 1100px !important;
}

.react-responsive-modal-modal.university {
  border-radius: 10px;
  /* max-width: inherit !important; */
  overflow: visible;
  /* max-width: 600px !important; */
}

.react-responsive-modal-closeButton.university {
  top: 1px;
  right: -18px;
}

.inverted {
  color: #d04c8d !important;
  background: transparent !important;
  border: 1px solid #d04c8d !important;
  box-sizing: border-box !important;
  border-radius: 128px !important;
}

/*media query for datatable*/
/*06-01-2022*/
@media only screen and (max-width: 990px) {
  .rdt_TableHead {
    display: none !important;
  }
  .rdt_TableRow {
    display: grid !important;
    padding: 0.5rem 0 0.5rem 1.5rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .users-management .rdt_TableHead {
    display: flex !important;
  }
  .users-management .rdt_TableRow {
    display: flex !important;
    padding: unset !important;
  }
}

/* 07-01-22 */
.dropdown__toggle button {
  display: flex;
  align-items: center;
  border: 0 !important;
  padding: unset !important;
  box-shadow: unset !important;
}

.dropdown__toggle1 button {
  border: 0 !important;
  box-shadow: unset !important;
}

.loginDropdown {
  margin-right: 13px;
}

.dropdown__toggle .dropdown-menu {
  right: 0 !important;
  margin-top: 0.75rem !important;
  left: unset !important;
  border: unset !important;
  box-shadow: 0px 0px 10px #d9d9d9;
  border-radius: 0.5rem !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-item {
  color: #acacac !important;
}

.dropdown-item:hover {
  color: #d04c8d !important;
  background-color: unset !important;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #d04c8d;
  width: 23px;
  height: 23px;
  /* border: 0.23px solid #d04c8d;
  border-radius: 50%;
  transform: translateY(-0.075em); */

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  background: white;
  border-radius: 100%;
  border: 1px solid #d04c8d;
  display: inline-block;
  width: 23px;
  height: 23px;
  position: relative;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  /* Windows High Contrast Mode */
  background-color: #f4f4f4;
}

input[type="radio"]:checked::before {
  background-color: #d04c8d;
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border: 2px solid #d04c8d;
}
/* ------------------------- tooltip for input tag starts -------------------*/
[data-tip] {
  position: relative;
}

[data-tip]:before {
  content: "";
  /* hides the tooltip when not hovered */
  display: none;
  content: "";
}

[data-tip]:before {
  content: "";
  /* hides the tooltip when not hovered */
  display: none;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #1a1a1a;
  position: absolute;
  top: 30px;
  left: 35px;
  z-index: 8;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}

[data-tip]:after {
  display: none;
  content: attr(data-tip);
  position: absolute;
  top: 35px;
  left: 0px;
  padding: 5px 8px;
  background: #1a1a1a;
  color: #fff;
  z-index: 9;
  font-size: 0.75em;
  height: 18px;
  line-height: 18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space: nowrap;
  word-wrap: normal;
}

[data-tip]:hover:before,
[data-tip]:hover:after {
  display: none;
}

/* ------------------------------------ tooltip Css ends---------------------- */
.users-management .rdt_TableHead .rdt_TableCol_Sortable div,
.rdt_TableHead rdt_TableCol div {
  font-size: small;
  font-weight: 600;
}

/* MUI calender css override */

.MuiInput-underline:after,
.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInputBase-input {
  font-family: inherit !important;
  background-color: #f3f3f3 !important;
  border-radius: 128px !important;
  padding: 12px 20px !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: rgba(133, 133, 133, 1) !important;
  height: unset !important;
}

/* Add Student Modal css override */
/* for small devices */
@media only screen and (max-width: 768px) {
  .add-student-modal .modal-dialog {
    max-width: max-content;
  }
}
/* for medium devices */
@media only screen and (max-width: 992px) {
  .add-student-modal .modal-dialog {
    max-width: 90%;
  }
}
/* for large devices */
@media only screen and (min-width: 992px) {
  .add-student-modal .modal-dialog {
    max-width: calc(100%-200px);
  }
}

.add-student-modal .modal-dialog {
  max-width: max-content;
}
.editOverflow > div {
  overflow: visible;
}

.color-bubble-chart .cell {
  cursor: pointer;
}

.color-bubble-chart .cell.hidden text tspan {
  text-decoration: line-through;
}

.color-bubble-chart a.hidden {
  display: none;
}

.color-bubble-chart .legendQuant text tspan {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #615e83;
}
.bubble-chart-wrapper .bubble-chart-tooltip {
  position: absolute;
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in;
  background-color: #1e1b39;
  border-radius: 5px;
  padding: 5px 10px;
  color: #ffffff;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 14.6918px;
  line-height: 22px;
  text-align: center;
  transform: translate(0px, -44px);
  box-sizing: border-box;
}
.bubble-chart-wrapper .bubble-chart-tooltip.show {
  position: absolute;
  display: inline-block !important;
  opacity: 1;
}

.bubble-chart-wrapper .bubble-chart-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e1b39 transparent transparent transparent;
}

.noFound{
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40;
  position: relative;
  text-align: center;
  margin: auto;
  font: normal normal 400 22px/ 24px Inter;
  white-space: nowrap;
  color: #615E83
}

.cursor-pointer {
  cursor: pointer;
}
.rdt_TableRow button:disabled {
  display: none;
}

.navbar-toggler {
  padding-left: 8px !important;
  font-size: unset !important;
  line-height: unset !important;
  color: unset !important;
  background-color: unset;
  border: unset !important;
  border-radius: 10px !important;
  transition: unset !important;
}
.navbar-toggler:focus {
  box-shadow: unset !important;
}
#react-select-5-listbox {
  width: 100%;
}

.searchInput {
  @media (max-width: 576px) {
    margin: 1.5rem 0;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #d04c8d !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #d04c8d !important;
  height: 1px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.button-no-style {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
}